import { template as template_318cb8e99008421b8fa243a9b4ac6e0a } from "@ember/template-compiler";
const FKControlMenuContainer = template_318cb8e99008421b8fa243a9b4ac6e0a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
