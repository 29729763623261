import { template as template_87f034aeaf194ece82459dffbdefd387 } from "@ember/template-compiler";
const WelcomeHeader = template_87f034aeaf194ece82459dffbdefd387(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
