import { template as template_8866c7e70afb4e6e80b8d9489e0f6801 } from "@ember/template-compiler";
const FKLabel = template_8866c7e70afb4e6e80b8d9489e0f6801(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
